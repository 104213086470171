import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { DATATABLE_TYPE } from 'state-domains/constants';
import { FilterObject } from 'state-domains/domain/drillhole';
import {
    buildConfigurationDispatchHeadersUrlWithFilters,
    buildConfigurationHeadersUrlWithFilters,
} from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';

export const loadConfigurationHeaders = (
    configType: DATATABLE_TYPE,
    filters: FilterObject[],
): Observable<any> => {
    const url =
        configType === DATATABLE_TYPE.HEADERS
            ? buildConfigurationHeadersUrlWithFilters(filters)
            : buildConfigurationDispatchHeadersUrlWithFilters(filters);
    const obs = sendRetrieveRequest(url);
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const data =
                configType === DATATABLE_TYPE.HEADERS
                    ? response.Header
                    : response.SampleDispatchHeader;
            const items = convertToCamel<any>(data);
            return observableOf(items);
        }),
    );
};
